<template>
  <div class="mem-map">
    <div id="ol-map"></div>
    <div class="map-controls">
      <div class="zoom-button" @click="zoomMap(1)">
        <i class="material-icons">add</i>
      </div>
      <div class="controls-divider"></div>
      <div class="zoom-button" @click="zoomMap(-1)">
        <i class="material-icons">remove</i>
      </div>
    </div>
  </div>
</template>
<script>
import Map from "ol/Map";
import View from "ol/View";
import Feature from "ol/Feature";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import { Point } from "ol/geom";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import { fromLonLat } from "ol/proj";
import { defaults as defaultControls } from "ol/control";
// import XYZ from "ol/source/XYZ";

const ol = {
  Map,
  View,
  Feature,
  TileLayer,
  VectorLayer,
  VectorSource,
  OSM,
  Point,
  fromLonLat,
  defaultControls,
};

import markerIcon from "@/assets/marker.svg";

export default {
  data: () => ({
    image: markerIcon,

    mapView: null,
  }),
  props: ["event"],
  methods: {
    initMap() {
      let { latitude, longitude } = this.event;
      let center = [longitude, latitude];

      let pointFeature = new ol.Feature(new ol.Point(ol.fromLonLat(center)));
      this.mapView = new ol.View({
        zoom: 15,
        // minZoom: 5,
        center: ol.fromLonLat(center),
        constrainResolution: true,
      });
      new ol.Map({
        target: "ol-map",
        controls: ol.defaultControls({
          zoom: false,
          attribution: false,
          rotate: false,
        }),
        layers: [
          new ol.TileLayer({
            source: new ol.OSM(),
          }),
          new ol.VectorLayer({
            source: new ol.VectorSource({
              features: [pointFeature],
            }),
            style: {
              "icon-src": markerIcon,
            },
          }),
        ],
        view: this.mapView,
      });
    },
    zoomMap(zoomVal) {
      let zoom = this.mapView.getZoom();
      this.mapView.animate({ zoom: zoom + zoomVal });
    },
  },
  mounted() {
    setTimeout(() => {
      this.initMap();
    }, 1000);
  },
};
</script>
<style lang="scss" scoped>
.mem-map {
  position: relative;
  #ol-map {
    height: 240px;
    width: 100%;
  }
  .map-controls {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    border-radius: 2px;

    .controls-divider {
      height: 1px;
      margin: 0 5px;
      background-color: rgb(230, 230, 230);
    }
    .zoom-button {
      height: 40px;
      width: 40px;
      color: rgb(102, 102, 102);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      @include cursorPointer;
      &:hover {
        color: #000000;
      }
    }
  }
}
</style>